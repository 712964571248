import React, {useContext} from 'react';

import GateCommon from './GateCommon';
import ModuleData from '../ModuleData';
import Context from '../context';
import {fenceTypesHuman} from "../../App";
import { useTranslation } from 'react-i18next';
import SummaryPageGate from 'Pdf/SummaryPage/SummaryPageGate';
import SummaryPageNonSymGate from 'Pdf/SummaryPage/SummaryPageNonSymGate';
import { calculateGateAreasAmount } from './gateFunctions';

const GatePage = ({render}) => {
  const {values, gateAreasAmount: areasAmount} = useContext(Context);
  const {width, space, chosen, height, gateType, leftWingWidth, rightWingWidth} = values.gate;
  const { t } = useTranslation();
  if (!chosen) return null;

  let leftArea = null;
  let rightArea = null;
  if (gateType === 'nonSymmetricalGate') {
    leftArea = leftWingWidth <= 2499 ? 1 : 2;
    rightArea = rightWingWidth <= 2499 ? 1 : 2;
  }

  return (
    <div className="new-page">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="pdfHeader">
              <h2>{t(fenceTypesHuman[gateType])}</h2>
              <div className="headerLine"/>
            </div>
            <div className="fenceVisualisation">
              <img src={`${render}`} alt=""/>
            </div>
            <div className="fenceTypeData">
              <GateCommon/>
              {gateType === 'nonSymmetricalGate' &&
              <p>
                <b>{t('leftWingWidth')}:</b> {leftWingWidth} mm
              </p>}
              {gateType === 'nonSymmetricalGate' &&
              <p>
                <b>{t('rightWingWidth')}:</b> {rightWingWidth} mm
              </p>}
              {gateType !== 'nonSymmetricalGate' &&
              <p>
                <b>{t('width')}:</b> {width} mm
              </p>}
              <p>
                <b>{t('height')}:</b> {height} mm
              </p>
              <p>
                <b>{t('space')}:</b> {space} mm
              </p>
              {(gateType === 'gateSelfSupporting' &&
                <p>
                  <b>{t('areasAmount')}:</b> {areasAmount} szt
                </p>
              )}
              {(gateType === 'nonSymmetricalGate' &&
                <>
                  <p>
                    <b>{t('leftAreaAmount')}:</b> {leftArea} szt
                  </p>
                  <p>
                    <b>{t('rightAreaAmount')}:</b> {rightArea} szt
                  </p>
                </>
              )}
              <ModuleData type="gate"/>
              {gateType === 'nonSymmetricalGate' && (
                <SummaryPageNonSymGate />
              )}
              {gateType !== 'nonSymmetricalGate' && (
                <SummaryPageGate />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GatePage;
