import React, {useContext} from 'react';

import Context, { calculateSpacesAmount } from '../context';
import {calculateGateAreaHight, calculateGateAreaWidth} from '../Gate/gateFunctions';
import ModuleData from '../ModuleData';
import { useTranslation } from 'react-i18next';

const SummaryPageNonSymGate = () => {
  const {
    values,
    gateRodsAmount: rodsAmount,
    gateStandardPlates: standardPlates,
    gateSmallPlates: smallPlates,
    gateNonStandardPlates: nonStandardPlates,
  } = useContext(Context);
  const {chosen, gateType, hinges, mountingStrip, rodsStructure , leftWingWidth, rightWingWidth} = values.gate;
  if (!chosen) return null;
  const leftArea = calculateGateAreaWidth({width: leftWingWidth, hinges, type: gateType, mountingStrip});
  const rightArea = calculateGateAreaWidth({width: rightWingWidth, hinges, type: gateType, mountingStrip});
  const totalPlates = standardPlates + smallPlates + nonStandardPlates;
  const spacesAmount = calculateSpacesAmount(rodsStructure);
  const { t } = useTranslation();
  return (
    <div className="sumUpData">
      <p>
        <b>{t('totalPlatesAmount')}:</b> {totalPlates} szt
      </p>
      <p>
        <b>{t('cSectionsTopBottomAmount')}:</b> {rodsAmount} szt
      </p>
      <p>
        <b>{t('spaceHigherThan0Amount')}:</b> {spacesAmount} szt
      </p>
      <p>
        <b>{t('leftArea')}:</b> {leftArea} mm
      </p>
      <p>
        <b>{t('rightArea')}:</b> {rightArea} mm
      </p>
      <p>
        <b>{t('platesPerGate')}:</b> {totalPlates} szt
      </p>
      <ModuleData type="gate"/>
    </div>
  );
};

export default SummaryPageNonSymGate;
